/* src/pages/ProductPage.css */
.product-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.product-page-image {
  flex: 1;
  padding: 20px;
}

.product-page-details {
  flex: 2;
  padding: 20px;
}

.product-name {
  font-size: 2em;
  margin-bottom: 10px;
}

.product-price {
  font-size: 1.5em;
  color: #b12704;
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 20px;
}

.product-specifications p {
  margin: 5px 0;
}

.add-to-cart-container {
  display: flex;
  align-items: center;
}

.quantity-selector {
  width: 60px;
  margin-right: 10px;
}

.add-to-cart-button {
  background-color: #f0c14b;
  border: 1px solid #a88734;
  padding: 10px 20px;
  cursor: pointer;
}

.product-reviews, .related-products {
  width: 100%;
  max-width: 1200px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
}

.loading-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it's above other content */
}
