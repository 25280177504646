/* src/components/ProductCard.css */
.product-card {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  margin: 5px 1px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eaeaea;
  transition: opacity 0.3s ease-in-out;
}

.product-image.loading {
  opacity: 0.5;
}

.product-details {
  padding: 16px;
}

.product-name {
  font-size: 1.5em;
  margin: 0;
}

.product-description {
  margin: 8px 0;
}

.product-price,
.product-type,
.product-category {
  margin: 4px 0;
}
