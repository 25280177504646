/* src/pages/HomePage.css */
.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.homepage-header,
.homepage-footer {
  background-color: #f8f9fa;
  padding: 1rem;
  text-align: center;
}

.homepage-content {
  flex: 1;
  padding: 2rem;
}

.error {
  color: red;
}

.loader-container {
  height: 100vh;
  width: 100vw;
  align-content: center;
}

.loader {
  text-align: center;
}

@media (max-width: 768px) {
  .homepage-content {
    padding: 1rem;
  }
}