.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.app-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.product-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
}

.product-details {
  padding: 10px;
}

.product-name {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.product-description {
  color: #666;
  margin-bottom: 10px;
}

.product-price {
  font-weight: bold;
  margin-bottom: 5px;
}

.product-type, .product-category {
  color: #888;
}
