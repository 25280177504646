/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #232f3e;
    color: white;
    padding: 0.5rem 1rem;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar-logo {
    cursor: pointer;
  }
  
  .logo-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .navbar-search {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 1rem;
  }
  
  .navbar-search input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .navbar-search input:focus {
    border-color: #ff9900;
  }
  
  .navbar-search button {
    padding: 0.5rem 1rem;
    border: none;
    background-color: #ff9900;
    color: white;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navbar-search button:hover {
    background-color: #e68a00;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin: 0;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }
  
  .navbar-links a:hover {
    color: #ff9900;
  }
  
  .navbar-icons {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .navbar-icon {
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 1rem;
  }
  
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navbar-logo,
    .navbar-search,
    .navbar-links,
    .navbar-icons {
      width: 100%;
      margin-top: 0.5rem;
    }
  
    .navbar-search {
      margin: 0;
    }
  
    .navbar-search input {
      flex: 1;
    }
  
    .navbar-search button {
      width: auto;
      border-radius: 0 4px 4px 0;
    }
  
    .navbar-links {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
  